import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/vercel/path0/frontend/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/node_modules/@liquorice/next-apollo/ApolloWrapper/ApolloWrapperInternal.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fpalette.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA63V3WrDIBTA8fs%2BheDNCu3QGKO1t9uDJBqT9csta7sv9u7DBsZJKYcDG979Y3%2BcKiRuSOnIvmaMLZdR7zdpJxzjohLKhjWo8mYtHOOqVsZ7WJVj3BqrY4S1dIz7ypfTqh3jUcervZVjvA1CWAmryXuNb4OB1eYabawVrKtLbUU72VvnGqKKGtbmsjeuYg2rd4w%2FSl2oB1hDPgehpZ5M1jrGjc4L1ugYr31esHaO8dLmBWs%2FzgDTUz6EQggh1rPv2f0Yd5O72jh2roe736ubw99vpw%2FNHDB7jJEY00PmgDEKYyRkEsYU5GmeMaYkT%2FOCMZrMDBhTkf%2FUK8YY8jRHjFmRmRPGWDJz%2Fh%2FmDWMaMvOOMZ58Ux8YE8jMJ8a0ZEYKzImY000ciTkdfZ4Cc3ryZUmFOU%2F0eco%2FztOkMN64T7s0XB3LIn8lxtdxU%2FttN6TTISxv7OwXl%2Fd%2BFn8Ae5bcZ4sHAAA%3D%22%7D");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Flayouts%2FAppLayout%2FAppLayout.css.ts.vanilla.css%22%2C%22source%22%3A%22Lm1mODM3MTAgewogIG92ZXJmbG93OiBoaWRkZW47CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwogIG1pbi1oZWlnaHQ6IDEwMHZoOwp9Ci5tZjgzNzFsIHsKICBmbGV4LWdyb3c6IDE7Cn0%3D%22%7D");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/_DevToolbar/DevToolbar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/ShortlistDrawer/ShortlistDrawer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/ThemeProvider/ThemeProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/theme/global.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/layouts/AppLayout/AppLayout.css.ts");
