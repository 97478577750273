'use client';

import useShortlistStore from '@/lib/store/shortlist';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslations } from 'next-intl';
import { usePathname, useRouter } from 'next/navigation';
import { useState } from 'react';
import Box from '../ui/Box';
import Btn from '../ui/Btn';
import Flex, { FlexProps } from '../ui/Flex';
import Txt from '../ui/Txt';

type ShortlistCardProps = FlexProps;

const ShortlistCard = (props: ShortlistCardProps) => {
  const [copy, setCopy] = useState<boolean>(false);

  const router = useRouter();
  const pathname = usePathname();
  const t = useTranslations('actions');

  const events = useShortlistStore((s) => s.events);
  const eventIds = events?.map((event) => `id=${event.id}`);

  const uriString = eventIds?.join('&');

  const pushNewParams = () => {
    router.push(`/shortlist/?${uriString}`);
  };

  const TIMEOUT = 1500;

  const handleClick = () => {
    setCopy(true);
    navigator.clipboard.writeText(`${window.location.origin}${pathname}?${uriString}`);

    const timeoutId = setTimeout(() => {
      setCopy(false);
    }, TIMEOUT);

    return () => clearTimeout(timeoutId);
  };

  return (
    <Flex rowGap="2xs" colorSet="primaryTintLighter" paper cx={{ p: 'sm' }} {...props}>
      <Txt variant="tiny" monoFont uppercase>
        {t('shareShortlist')}
      </Txt>
      <Box>
        <Btn
          onClick={pushNewParams}
          startIcon="chevronRight"
          variant="text"
          color="primary"
          underline="always">
          {t('linkToShortlist')}
        </Btn>
      </Box>
      <Box>
        <Btn
          onClick={handleClick}
          startIcon="chevronRight"
          variant="text"
          color="primary"
          underline="always">
          {t('copyLink')}
        </Btn>
        <AnimatePresence>
          {copy && (
            <Btn
              as={motion.button}
              initial={{ opacity: 0, x: -5 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0 }}
              color="primary"
              variant="text"
              size="tiny"
              underline="none"
              endIcon="check"
              cx={{ mL: 'md' }}>
              {t('copied')}
            </Btn>
          )}
        </AnimatePresence>
      </Box>
    </Flex>
  );
};

export default ShortlistCard;
